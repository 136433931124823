@import url("https://fonts.googleapis.com/css2?family=League+Spartan&display=swap");

* {
  font-family: "League Spartan", sans-serif;
}

.App {
  text-align: center;
}

.App-logo {
  height: 100px;
  margin-left: auto;
  margin-right: auto;
  pointer-events: none;
}

.landing_screen {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.btn_primary {
  padding: 2px 5px;
  border: 1px solid transparent;
  border-radius: 3px;
  font-size: 14px;
  color: #000;
  background-color: #fff;
  box-shadow: 0px 0px 2px 2px rgba(255, 255, 255, 1);
  -webkit-box-shadow: 0px 0px 2px 2px rgba(255, 255, 255, 1);
  -moz-box-shadow: 0px 0px 2px 2px rgba(255, 255, 255, 1);
}

.sceneWrapper {
  /* background-color: rgba(0, 0, 0, 0.85); */
  height: 100vh;
}

.overlay-logo {
  position: absolute;
  top: 15px;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0.5;
  width: 100px;
  z-index: 10;
}

.progress-wrapper {
  @apply absolute h-screen w-screen bg-gray-700;
  z-index: 200;
}

.progress-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  font-size: 14px;
  padding: 2px 5px;
  background-color: #fff;
  box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}

.landing_screen.pause {
  background-color: #00000085;
  position: absolute;
  height: 100vh;
  width: 100vw;
  z-index: 10;
}

.landing_screen.pause.hide {
  display: none;
}

.controlsInterfaceWrapper {
  @apply absolute p-5 w-full flex flex-wrap justify-center;
  left: 50%;
  bottom: 5%;
  transform: translateX(-50%);
}

.controlsInterfaceWrapper .itemGroup {
  @apply flex flex-wrap m-5 items-center;
}

.controlsInterfaceWrapper .itemGroup .inputKey {
  @apply h-7 w-7 relative rounded-sm bg-white text-black text-lg;
  box-shadow: 0px 0px 1px 2px rgba(255, 255, 255, 1);
  -webkit-box-shadow: 0px 0px 1px 2px rgba(255, 255, 255, 1);
  -moz-box-shadow: 0px 0px 1px 2px rgba(255, 255, 255, 1);
}

.controlsInterfaceWrapper .itemGroup .inputDesc {
  @apply text-white text-sm ml-3;
}

.controlsInterfaceWrapper .itemGroup .inputKey span {
  @apply absolute;
  top: 10%;
  left: 50%;
  transform: translateX(-50%)
}

.more_bg {
  @apply min-h-screen p-10;
  background-color: #282c34;
}

.more_bg .wrapper {
  @apply flex flex-wrap justify-center;
  color: #fff;
  font-size: 18px;
  text-align: center;
}

.link_btn {
  @apply w-full my-5 bg-white rounded-lg px-3 py-4 text-black;
  box-shadow: 0 0px 8px 1px rgba(255, 255, 255, 0.3);
}

.walletBtnWrapper {
  @apply absolute z-10 top-6 right-6 flex flex-wrap items-center;
}

.connectWalletBtn img {
  @apply w-10 h-10 transition-all;
}

.connectWalletBtn:hover img {
  transform: scale(0.95);
}

.connectWalletBtn:active img {
  transform: scale(0.8);
}

.nftBtn {
  @apply ml-5;
}

.nftMenuWrapper {
  @apply absolute flex flex-wrap items-center justify-center;
  height: 450px;
  width: 400px;
  z-index: 20;
  top: 30%;
  bottom: 0;
  right: 5%;
  padding: 15px;
  background-color: rgba(255, 255, 255, 0.95);
}

.nftWrapper {
  width: 50%;
  padding: 5px;
}

.nftWrapper.selected {
  @apply border-2 border-cyan-300;
}

.nftWrapper img {
  width: 100%;
}

.nftWrapper.selected img {
  opacity: 0.9;
}

.nftMenuInstruction {
  @apply w-full flex flex-wrap justify-between items-center;
  padding: 5px;
}

.inputKey {
  @apply inline-block p-1 mr-3 relative rounded-sm bg-white text-black text-lg;
  box-shadow: 0px 0px 1px 2px rgba(255, 255, 255, 1);
  -webkit-box-shadow: 0px 0px 1px 2px rgba(255, 255, 255, 1);
  -moz-box-shadow: 0px 0px 1px 2px rgba(255, 255, 255, 1);
}

.nftMsg {
  @apply absolute text-white;
  left: 80px;
  bottom: 40px;
  z-index: 10;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.nftMsg .inputKey {
  @apply inline-block h-7 w-7 mr-3 relative rounded-sm bg-white text-black text-lg;
  box-shadow: 0px 0px 1px 2px rgba(255, 255, 255, 1);
  -webkit-box-shadow: 0px 0px 1px 2px rgba(255, 255, 255, 1);
  -moz-box-shadow: 0px 0px 1px 2px rgba(255, 255, 255, 1);
}

.targetLabel {
  @apply absolute text-lg py-3 px-5 text-white rounded-md;
  /* background-color: rgba(255, 255, 255, 0.836); */
  z-index: 100;
  left: 50%;
  top: 45%;
  transform: translateX(-50%);
}

.targetLabelTitle {
  @apply font-bold text-xl;
  text-shadow: 0 0 4px rgb(255, 255, 255);
}

.targetLabelText {
  @apply font-thin;
}

.targetLabelText div {
  @apply text-sm text-right;
  margin-top: -5px;
}

.targetLabelText .inputKey {
  @apply h-7 w-7 mr-3 text-center rounded-sm bg-white text-black text-lg;
  box-shadow: 0px 0px 1px 2px rgba(255, 255, 255, 1);
  -webkit-box-shadow: 0px 0px 1px 2px rgba(255, 255, 255, 1);
  -moz-box-shadow: 0px 0px 1px 2px rgba(255, 255, 255, 1);
}